/* eslint-disable no-async-promise-executor */
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';

export const getServerTimeStamp = () =>
  firebase.firestore.FieldValue.serverTimestamp();
const {
  REACT_APP_FB_API_KEY,
  REACT_APP_FB_AUTH_DOMAIN,
  REACT_APP_FB_DATABASE_URL,
  REACT_APP_FB_PROJECT_ID,
  REACT_APP_FB_STORAGE_BUCKET,
  REACT_APP_FB_SENDER_ID,
  REACT_APP_FB_APP_ID,
  REACT_APP_FB_MEASUREMENT_ID,
} = process.env;

const config = {
  apiKey: REACT_APP_FB_API_KEY,
  authDomain: REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: REACT_APP_FB_DATABASE_URL,
  projectId: REACT_APP_FB_PROJECT_ID,
  storageBucket: REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FB_SENDER_ID,
  appId: REACT_APP_FB_APP_ID,
  measurementId: REACT_APP_FB_MEASUREMENT_ID,
};
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}
let pedidoIdFetch = '';
const fbfirestore = firebase.firestore();

export const analytics = {
  logEvent: (name, params) => {
    if (process.env.NODE_ENV === 'development') return;
    firebase.analytics().logEvent(name, params);
  },
};

export const firestore = {
  add: ({ carrinho, nome, endereco }) => {
    let collection = 'pedidos';
    if (process.env.NODE_ENV === 'development') collection = 'pedidos_dev';
    const pedidoId = fbfirestore.collection(collection).doc().id;
    fbfirestore
      .collection(collection)
      .doc(pedidoId)
      .set({
        carrinhoId: carrinho.id,
        id: pedidoId,
        carrinho,
        nome,
        endereco,
        created_at: getServerTimeStamp(),
      })
      .catch(() => {
        analytics.logEvent('db_failure_add_carrinho', {
          carrinhoId: carrinho.id,
        });
      });
    return pedidoId;
  },
  udpate: async ({ carrinho, nome, endereco, pedidoId }) => {
    let collection = 'pedidos';
    if (process.env.NODE_ENV === 'development') collection = 'pedidos_dev';
    try {
      if (!pedidoId && !pedidoIdFetch) {
        const query = await fbfirestore
          .collection(collection)
          .where('carrinhoId', '==', carrinho.id)
          .get();
        if (query.size) {
          pedidoIdFetch = query.docs[0].id;
        }
      }
      await fbfirestore
        .collection(collection)
        .doc(pedidoId || pedidoIdFetch)
        .update({
          carrinho,
          nome,
          endereco,
          udpated_at: getServerTimeStamp(),
        });
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log(
          'db_failure_update_carrinho',
          JSON.stringify(error, 2, null),
        );
      }
      analytics.logEvent('db_failure_update_carrinho', {
        carrinhoId: carrinho.id,
        pedidoId,
      });
    }
  },
};

export { firebase as firebaseRef };
