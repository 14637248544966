/* eslint-disable no-async-promise-executor */
import axios from 'axios';
import {
  formatarPlanilhaDelivery,
  formatarPlanilhaAcrescimos,
  generatePlanilhaFromApiV4,
  formatStringPriceToNumber,
} from 'helpers';

const {
  REACT_APP_PLANILHA_DELIVERY_V4,
  REACT_APP_PLANILHA_DELIVERY_BAIRROS_V4,
  REACT_APP_PLANILHA_DELIVERY_PIZZA_V4,
  REACT_APP_PLANILHA_DELIVERY_HORARIOS_V4,
  REACT_APP_PLANILHA_DELIVERY_ACRESCIMOS,
} = process.env;

export const getBairros = () =>
  new Promise(async (resolve, reject) => {
    try {
      const resp = await axios.get(REACT_APP_PLANILHA_DELIVERY_BAIRROS_V4);
      if (!resp.data || resp.status > 201) throw resp;
      const { data } = resp;

      const dados = generatePlanilhaFromApiV4(data?.values, true);

      resolve(formatStringPriceToNumber(dados, 'taxa'));
    } catch (error) {
      reject(error);
    }
  });

export const getAcrescimos = () =>
  new Promise(async (resolve, reject) => {
    try {
      const resp = await axios.get(REACT_APP_PLANILHA_DELIVERY_ACRESCIMOS);
      if (!resp.data || resp.status > 201) throw resp;
      const { data } = resp;

      const dados = formatarPlanilhaAcrescimos(data);

      resolve(dados);
    } catch (error) {
      reject(error);
    }
  });

export const getHorarios = () =>
  new Promise(async (resolve, reject) => {
    try {
      const resp = await axios.get(REACT_APP_PLANILHA_DELIVERY_HORARIOS_V4);
      if (!resp.data || resp.status > 201) throw resp;
      const { data } = resp;

      const dados = generatePlanilhaFromApiV4(data?.values, true);

      resolve(dados);
    } catch (error) {
      reject(error);
    }
  });

export const getPlanilhaDelivery = () =>
  new Promise(async (resolve, reject) => {
    try {
      const respV4 = await axios.get(REACT_APP_PLANILHA_DELIVERY_V4);

      const resp = await require('./__mocks__/planilha.json');
      const respPizzasV4 = await axios.get(
        REACT_APP_PLANILHA_DELIVERY_PIZZA_V4,
      );

      if (
        !respV4.data ||
        respV4.status > 201 ||
        !respPizzasV4.data ||
        respPizzasV4.status > 201
      )
        throw resp;
      const { data } = resp;

      const bairros = await getBairros();

      // const bairros2 = await require('./__mocks__/bairros.json');

      const horarios = await getHorarios();
      // const horarios = await require('./__mocks__/horarios.json');

      // const acrescimos_grupo = await getAcrescimos();
      const acrescimos_grupo = await require('./__mocks__/acrescimos_grupo.json');

      const { data: dataV4 } = respV4;

      const dados = formatarPlanilhaDelivery(
        data,
        acrescimos_grupo,
        true,
        dataV4?.values,
      );

      const { data: dataPizzasV4 } = respPizzasV4;
      const cardapioPizzas = formatarPlanilhaDelivery(
        {},
        acrescimos_grupo,
        true,
        dataPizzasV4?.values,
      );

      resolve({ dados, bairros, horarios, cardapioPizzas });
    } catch (error) {
      reject(error);
    }
  });
