/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { analytics } from 'config/firebase';
import chamalogo from 'assets/images/chama.svg';
import { LogoChama, LogoImage } from '../styles';

const HeaderChama = () => {
  return (
    <LogoChama>
      <a
        href="https://chamaodelivery.com/"
        target="_blank"
        alt="Chama o Delivery"
        onClick={() => {
          analytics.logEvent('pedido_chamadelivery_click');
        }}
      >
        <LogoImage alt="logo" height={30} src={chamalogo} />
      </a>
    </LogoChama>
  );
};

export default HeaderChama;
