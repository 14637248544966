import styled from 'styled-components';
import { COLORS } from 'styles';
import { Button, Image } from 'react-bootstrap';
import { isMobileOrTablet } from 'helpers';

export const LogoChama = styled.div`
  width: '100%';
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 10px 0px;
  background: #872525;
`;

export const LogoContent = styled.div`
  width: '100%';
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 15px 0px;
  background: ${COLORS.secondary};
`;

export const LogoImage = styled.img`
  height: ${props => props.height || 70}px;
  text-align: center;
`;

export const Texto = styled.p`
  margin: 0;
  font-family: 'Oswald', sans-serif;
  color: ${COLORS.texto} !important;
  font-size: 0.85rem;
  letter-spacing: 0.9px;

  a {
    font-size: 0.85rem;
    color: ${COLORS.texto} !important;
  }
`;

export const SubTexto = styled.p`
  margin: 0;
  font-family: 'Oswald', sans-serif;
  color: ${COLORS.texto} !important;
  font-size: 0.8rem;
  letter-spacing: 0.9px;
  opacity: 0.8;
  a {
    font-size: 0.85rem;
    color: ${COLORS.texto} !important;
  }
`;

export const Section = styled.section`
  background: #ecf0f1;
  padding: 20px 20px 20px;
  border-bottom: 2px solid ${COLORS.secondary};
  margin: 20px 0px;
  ${!isMobileOrTablet() ? `position: sticky;` : ''}
  position: -webkit-sticky; /* Safari */
  top: 0;
  z-index: 2;
  transition: all 0.3s ease 0s;
`;
export const TitleSection = styled.h1`
  color: ${COLORS.texto};
  margin: 0;
  font-size: calc(1rem + 0.5vw);
  font-family: 'Oswald';
  letter-spacing: 0.5px;
  /* box-shadow: 4px 2px 0px #fff; */
`;
export const BodyText = styled.span`
  color: ${COLORS.texto};
  margin: 0;
  font-size: calc(0.8rem + 0.5vw);
  font-family: 'Oswald';
  letter-spacing: 0.5px;
  /* box-shadow: 4px 2px 0px #fff; */
`;

export const ListItemTitle = styled.p`
  color: ${COLORS.texto};
  font-size: ${props => props.fontSize || '1.1rem'};
  font-weight: ${props => props.weight || 500};
  font-family: 'Oswald';
`;

export const ListItemDescription = styled.p`
  color: ${props => props.color || COLORS.texto};
  font-size: ${props => props.fontSize || '0.9rem'};
  opacity: ${props => props.opacity || 0.8};
  font-family: 'Oswald';
`;

export const ListItemValor = styled.p`
  display: inline-block;
  font-family: 'Oswald', sans-serif;
  color: ${COLORS.texto};
  font-size: ${props => props.fontSize || '1.1rem'};
  letter-spacing: 0.5px;
  font-weight: bold;
  opacity: 0.9;
`;
export const LineRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  p {
    margin: 0;
    margin-left: 10px;
    letter-spacing: 0.3px;
  }
  margin-bottom: 5px;
`;

export const ButtonError = styled(Button)`
  margin-top: 20px;
  color: white;
  background: ${COLORS.secondary};
  padding: 20px;
  font-weight: bold;
  padding-right: 40px;
  padding-left: 40px;
  text-decoration: none !important;
`;

export const ImageProduto = styled(Image)`
  width: 100%;
  height: 150px;
  border-radius: 4px;
  margin-bottom: 20px;
  object-fit: cover;
  @media screen and (min-width: 720px) {
    height: 300px;
  }
`;

export const CircleBadge = styled.span`
  right: 0px;
  padding: 4px 8px;
  margin-right: 0px;
  border-radius: 24px;
  opacity: 1;
  font-size: 12px;
  transition: all 0.3s ease 0s;
  font-weight: 600;
  text-align: center;
  visibility: visible;
  color: ${COLORS.texto};
  background: ${COLORS.primary};
`;

export const RadioLabel = styled.label`
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  span {
    display: inline-block;
    width: 24px;
    height: 24px;

    vertical-align: middle;
    cursor: pointer;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 3px solid #ffffff;
    background-color: #fff;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  }
`;

export const ButtonCustom = styled.button`
  cursor: pointer;
  text-align: center;
  margin: 0;
  border: none;
  outline: none;
  background: transparent;
  min-height: 24px;
  &:active,
  &:focus {
    outline: none;
    border: none;
  }
`;

export const PlusBttonSvg = styled.img`
  svg {
    fill: #e2e;
  }
`;

export const RadioButton = styled.input`
  display: none;

  &:checked + ${RadioLabel} {
    color: #333;
    font-weight: 700;
  }
  &:checked + ${RadioLabel} span {
    background-color: #ffffff;
    border: 5px solid ${COLORS.secondary};
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  }
  ${RadioLabel} span,
  input[type='radio']:checked + ${RadioLabel} span {
    -webkit-transition: background-color 0.24s linear;
    -o-transition: background-color 0.24s linear;
    -moz-transition: background-color 0.24s linear;
    transition: background-color 0.24s linear;
  }
`;
