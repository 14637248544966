import React, { useCallback } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { IoMdArrowRoundBack } from 'react-icons/io';
import PropTypes from 'prop-types';
import { COLORS } from 'styles';
import { useHistory } from 'react-router-dom';
import { ListItemTitle } from '../styles';

const HeaderContainer = ({ title, onClick }) => {
  const history = useHistory();
  const goBack = useCallback(() => {
    history.goBack();
  }, []);

  return (
    <>
      <Row className="align-items-center">
        <Col xs="auto" className="position-absolute" style={{ zIndex: 2 }}>
          <Button
            variant="link"
            style={{ color: COLORS.dark }}
            onClick={onClick || goBack}
          >
            <IoMdArrowRoundBack size={24} />
          </Button>
        </Col>
        <Col className="text-center">
          <ListItemTitle className="m-0">{title}</ListItemTitle>
        </Col>
      </Row>
      <hr />
    </>
  );
};

HeaderContainer.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
};

export default HeaderContainer;
