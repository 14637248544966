import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import logo from '../../assets/logo.png';
import iconInstagram from '../../assets/instagram.svg';
import iconWhats from '../../assets/whatsapp.svg';
import iconFacebook from '../../assets/facebook.svg';
import {
  FooterContainer,
  Texto,
  Logo,
  IconSocial,
  TextoDisplay,
} from './styles';

const Footer = () => {
  const location = useLocation();
  if (
    !location ||
    !['/', '/cardapio', '/pedido'].find(it => it === location.pathname)
  )
    return null;
  return (
    <FooterContainer className="d-flex flex-column h-100">
      <Container className="justify-content-center align-items-center">
        <Row className="border-bottom border-warning mb-3">
          <Col
            xs={12}
            lg={{ offset: 3, span: 6 }}
            className="text-center text-white pb-4"
          >
            <TextoDisplay
              fontSize="1.5em"
              className="text-white font-weight-bold"
            >
              Aviso sobre pedidos
            </TextoDisplay>
            <TextoDisplay className="text-white font-weight-light">
              Ao fazer o pedido você será redirecionado para o{' '}
              <strong>Whatsapp</strong> no celular ou no computador onde você
              deve se finalizar e comfirmar seu pedido!
            </TextoDisplay>
          </Col>
        </Row>
        <Row className="justify-content-center pb-4">
          <Col xs={2} lg={1} className="text-center">
            <a
              href={`https://www.facebook.com/${process.env.REACT_APP_PROJECT_FACEBOOK}`}
              target="_blank"
              without="true"
              title="Facebook"
              rel="noopener noreferrer"
            >
              <IconSocial src={iconFacebook} alt="logo" />
            </a>
          </Col>
          <Col xs={2} lg={1} className="text-center">
            <a
              href={`https://www.instagram.com/${process.env.REACT_APP_PROJECT_INSTAGRAM}/`}
              target="_blank"
              without="true"
              title="Instagram"
              rel="noopener noreferrer"
            >
              <IconSocial src={iconInstagram} alt="logo" fill="#fff" />
            </a>
          </Col>
          <Col xs={2} lg={1} className="text-center">
            <a
              href={`https://wa.me/${process.env.REACT_APP_PROJECT_WHATSAPP}`}
              target="_blank"
              without="true"
              title="Whatsapp"
              rel="noopener noreferrer"
            >
              <IconSocial src={iconWhats} alt="logo" />
            </a>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6} className="text-center text-white pb-4">
            <TextoDisplay>Horário de Funcionamento</TextoDisplay>
            {JSON.parse(process.env.REACT_APP_FOOTER_FUNCIONAMENTO || '[]').map(
              it => (
                <TextoDisplay key={it}>{it}</TextoDisplay>
              ),
            )}
          </Col>
          <Col className="text-center">
            <a href="/">
              <Logo src={logo} alt="logo" />
            </a>
            <p className="mt-1 mb-0">
              <small className="d-inline-block">
                <a
                  href={process.env.REACT_APP_PROJECT_GOOGLE_MAPS}
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Google Maps"
                >
                  <span className="d-block">
                    {process.env.REACT_APP_PROJECT_ENDERECO}
                  </span>
                  <span className="d-inline-block">
                    {process.env.REACT_APP_PROJECT_CIDADE} -{' '}
                    {process.env.REACT_APP_PROJECT_ESTADO_SIGLA}
                  </span>
                </a>
              </small>
            </p>
          </Col>
        </Row>

        <Row className="pb-1">
          <Col className="text-center text-white">
            <Texto>
              © COPYRIGHT {new Date().getFullYear()}{' '}
              {process.env.REACT_APP_PROJECT_EMPRESA.toUpperCase()} - TODOS OS
              DIREITOS RESERVADOS.
            </Texto>
          </Col>
        </Row>
        <Row className="pb-4">
          <Col className="text-center text-white">
            <a
              href="https://www.linkedin.com/in/cleandersonlobo/"
              target="_blank"
              without="true"
              title="Cleanderson Lobo"
              rel="noopener noreferrer"
            >
              <Texto>DESENVOLVIDO POR CLEANDERSON LOBO</Texto>
            </a>
          </Col>
        </Row>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
