/* eslint-disable react/prop-types */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { analytics } from 'config/firebase';
import { TitleCardapio, BorderTop } from './styles';

const SectionTitle = ({ title = 'CARDÁPIO' }) => {
  return (
    <>
      <Row className="justify-content-center align-items-center">
        <Col xs={12} className="text-center">
          <BorderTop />
        </Col>
      </Row>
      <Row
        as="a"
        href="/cardapio"
        onClick={() => {
          analytics.logEvent('home_cardapio_btn_click');
        }}
      >
        <Col>
          <TitleCardapio>{title}</TitleCardapio>
        </Col>
      </Row>
    </>
  );
};

export default SectionTitle;
