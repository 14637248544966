import React, { useMemo } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import PropTypes from 'prop-types';
import HeaderContainer from './HeaderContainer';

const ContainerPedidos = ({
  footerComponent = null,
  isHeader = false,
  titleHeader,
  onClickHeader,
  rowClassName = 'py-3',
  children,
}) => {
  const renderHeader = useMemo(() => {
    if (!isHeader) return null;

    return <HeaderContainer title={titleHeader} onClick={onClickHeader} />;
  }, [isHeader]);

  return (
    <Container fluid>
      <Row className={rowClassName}>
        <Col xs={12} md={{ offset: 2, span: 8 }} xl={{ offset: 3, span: 6 }}>
          {renderHeader}
          {children}
        </Col>
      </Row>
      {footerComponent}
    </Container>
  );
};

ContainerPedidos.propTypes = {
  footerComponent: PropTypes.element,
  isHeader: PropTypes.bool,
  titleHeader: PropTypes.string,
  onClickHeader: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};
ContainerPedidos.propTypes = {
  footerComponent: PropTypes.element,
  children: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

export default ContainerPedidos;
