import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import logo from 'assets/logo.png';
import plateImg from 'assets/img/sanduiche.png';
import {
  Header,
  Background,
  Logo,
  Title,
  Sublogo,
  Link,
  Section,
  Button,
  Texto,
} from './styles';

const ErrorApp = () => {
  return (
    <>
      <Helmet>
        <title>Ops.. ;(</title>
      </Helmet>
      <Header>
        <Background />
        <Container className="justify-content-center align-items-center flex-1">
          <Row className="justify-content-center align-items-center pb-lg-5 pb-2">
            <Col xs={12} className="text-center">
              <a href="/">
                <Logo src={logo} alt="logo" />
              </a>
              <Link
                href={process.env.REACT_APP_PROJECT_GOOGLE_MAPS}
                rel="noopener noreferrer"
                target="_blank"
                title="Google Maps"
              >
                <Sublogo>
                  {process.env.REACT_APP_PROJECT_ENDERECO_COMPLETO}
                </Sublogo>
                <Sublogo>Quixadá - CE</Sublogo>
              </Link>
            </Col>
          </Row>
          <Row className="mt-5 justify-content-center">
            <Col lg={6} className="text-center">
              <Title>Ops! Aconteceu algo de errado.</Title>
            </Col>
          </Row>
        </Container>
      </Header>
      <Section id="404">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg={6} className="text-center justify-content-center">
              <Texto>Tente novamente</Texto>
              <Button href="/">Atualizar</Button>
            </Col>
            <Col lg={6} className="text-center">
              <a href="/cardapio">
                <img
                  src={plateImg}
                  alt="Prato de comida"
                  className="img-responsive"
                />
              </a>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

ErrorApp.propTypes = {};

export default ErrorApp;
