import styled from 'styled-components';

export const COLORS = {
  primary: '#FDD918',
  secondary: '#A62E2D',
  accent: '#FB9A02',
  success: '#2ecc71',
  red: '#d63031',
  warning: '#eccc68',
  texto: '#413221',
  light: '#bdc3c7',
  dark: '#1c1c1c',
};

export const ItemTitle = styled.p`
  color: ${COLORS.texto};
  font-size: ${props => props.fontSize || '1.1rem'};
  font-weight: ${props => props.weight || 500};
  opacity: ${props => props.opacity || 1};
  font-family: 'Oswald';
`;

export const Title = styled.h1`
  color: ${COLORS.texto};
  margin: 0;
  font-size: calc(1.2rem + 0.5vw);
  font-family: 'Oswald';
  letter-spacing: 0.5px;
  /* box-shadow: 4px 2px 0px #fff; */
`;
