/* eslint-disable import/no-cycle */

import { TIPO_PAGAMENTOS } from 'pages/Pedidos/components/TipoPagamento';
import { format } from 'date-fns';
import { DELIVERY_OPTIONS } from 'services/config';
/**
 * 
 * carrinho: {
    total: 13,
    quantidade: 1,
    items: [
      {
        id: new Date().getTime(),
        quantidade: 1,
        nome: '',
        total: 13,
        observacao: '',
        selected: PLANILHA.ENTRADAS[0],
      },
    ],
  },
 */
/*
  entrada: ['entrega', 'retirar']
  pagamento: ['credito', 'debito', 'dinheiro']
  troco se for dinheiro
 */
/*
*Pedido Saborear Restaurante #*
---------------------------------------

*1x Nonna´s filé (acompanha crostine de pizza) R$ 36,90*   

*Total:* R$ 36,90

---------------------------------------

*Teste* // nome
(55) 98989-8998 // telefone

*Retirada no local* // tipo entrega

*Pagamento:* Crédito  

_Pedido gerado pelo site às 22:14_

*/

const getOpcoes = ({ opcoes, acrescimosItems, acrescimos }) => {
  if (!opcoes && !acrescimos) return '';
  let stringFinal = '';
  if (opcoes) {
    stringFinal = opcoes.items.map(it => `\n• ${it}`);
  }

  if (!acrescimos || !Object.values(acrescimosItems).length) return stringFinal;

  const acrescimosString = Object.values(acrescimosItems)
    .filter(it => !!it)
    .map(it => `\n+ ${it.quantidade} ${it.nome}`);
  return `${stringFinal}${acrescimosString}`;
};

const customTipoDePagamento = (tipoPagamento, carrinho) => {
  switch (tipoPagamento.id) {
    case 'dinheiro': {
      if (carrinho.troco) return `*Troco para:* R$ ${carrinho.troco}\n`;
      return '\n';
    }
    case 'pix': {
      return `\n*Chave PIX:* ${tipoPagamento.chave}\n*Enviar comprovante para confirmação.*\n\n`;
    }

    default:
      return '\n';
  }
};
const customEntrega = (tipoEntrega, carrinho, endereco, numeroMesa) => {
  switch (carrinho.entrega) {
    case 'entrega': {
      return `*Endereço:*\n${endereco}`;
    }
    case 'mesa': {
      if (!numeroMesa) return '';
      return `*${tipoEntrega.nome}*\n*Número:* ${numeroMesa}`;
    }
    default:
      return `*${tipoEntrega.nome}*`;
  }
};
export const whatsappLink = ({
  carrinho,
  nome,
  endereco,
  pedidoId,
  numeroMesa,
}) => {
  const itemsTexto = carrinho.items.reduce((texto, item) => {
    return texto.concat(
      `*${item.quantidade}x (${item.codigo}) ${
        item.nome
      } R$${item.total.toFixed(2)}*${getOpcoes(item)}${
        item.observacao ? `\n_Obs: ${item.observacao}` : ''
      }\n\n`,
    );
  }, '');
  const tipoPagamento = TIPO_PAGAMENTOS.find(
    it => it.id === carrinho.pagamento,
  );
  const tipoEntrega = DELIVERY_OPTIONS.find(it => it.id === carrinho.entrega);
  const taxaEntrega =
    carrinho.entrega === 'entrega' &&
    !tipoEntrega.taxa &&
    tipoEntrega.isBairro &&
    carrinho.bairro
      ? carrinho.bairro.taxa
      : 0;
  return window.encodeURIComponent(
    `*Pedido ${process.env.REACT_APP_PROJECT_EMPRESA}${
      tipoEntrega.id === 'mesa' ? ` -  MESA(${numeroMesa})` : ''
    }*
---------------------------------------\n
${itemsTexto}${
      carrinho.entrega === 'entrega' && tipoEntrega.taxa
        ? `*Taxa de entrega*: ${tipoEntrega.taxa}`
        : ''
    }${
      carrinho.entrega === 'entrega' &&
      !tipoEntrega.taxa &&
      tipoEntrega.isBairro &&
      carrinho.bairro
        ? `*Taxa de entrega*: R$ ${carrinho.bairro.taxa.toFixed(2)}`
        : ''
    }
*Total:* R$ ${(carrinho.total + taxaEntrega).toFixed(2)}
---------------------------------------
*Nome:* *${nome}*\n
${customEntrega(tipoEntrega, carrinho, endereco, numeroMesa)}\n${
      carrinho.bairro ? `*BAIRRO:* ${carrinho.bairro.nome}` : ''
    }
*Pagamento:* ${tipoPagamento.nome}
${customTipoDePagamento(
  tipoPagamento,
  carrinho,
)}_Pedido gerado pelo site às ${format(
      new Date(),
      "dd/MM/yyyy 'às' hh:mm:ss",
    )}_\n#${pedidoId || ''}`,
  );
};
