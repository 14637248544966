/* eslint-disable prefer-spread */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
/* eslint-disable no-case-declarations */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/react-in-jsx-scope */
import {
  createContext,
  useReducer,
  useEffect,
  useCallback,
  createRef,
  useRef,
} from 'react';

import { getPlanilhaDelivery } from 'services/api';
import { analytics, firestore } from 'config/firebase';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { whatsappLink } from '../helpers/whatsappLink';
import { verificarIsAberto } from '../helpers/utils';
import { isDateMoreOneDay } from '../helpers';
import { generateUid } from '../helpers/utils';

export const KEY_STORAGE = `${process.env.REACT_APP_PROJECT_NAME}/carrinho`;
export const KEY_STORAGE_SCROLL_LEFT = `${process.env.REACT_APP_PROJECT_NAME}/scrolLeft`;
export const KEY_STORAGE_NOME = `${process.env.REACT_APP_PROJECT_NAME}/nome`;
export const KEY_STORAGE_ENDERECO = `${process.env.REACT_APP_PROJECT_NAME}/endereco`;
export const KEY_STORAGE_TELEFONE = `${process.env.REACT_APP_PROJECT_NAME}/telefone`;
export const KEY_STORAGE_PEDIDO_ID = `${process.env.REACT_APP_PROJECT_NAME}/pedidoId`;
/**
 * 
 * carrinho: {
    total: 13,
    quantidade: 1,
    items: [
      {
        id: new Date().getTime(),
        quantidade: 1,
        total: 13,
        observacao: '',
        selected: PLANILHA.ENTRADAS[0],
      },
    ],
  },
 */
/*
  subtotal: {
    items: []
    total: 0
  }
  entrada: ['entrega', 'retirar']
  pagamento: ['credito', 'debito', 'dinheiro']
  troco se for dinheiro
 */
const initialState = {
  isLoading: false,
  pedidoId: '',
  numeroMesa: null,
  carrinho: {
    id: generateUid(),
    total: 0,
    subtotal: 0,
    quantidade: 0,
    entrega: null,
    selectedBairro: false,
    bairro: null,
    pagamento: null,
    troco: null,
    items: [],
    createAt: new Date(),
    finalizado: null,
  },
  bairros: [],
  selectedItem: null,
  cardapio: null,
  telefone: localStorage.getItem(KEY_STORAGE_TELEFONE) || '',
  endereco: localStorage.getItem(KEY_STORAGE_ENDERECO) || '',
  nome: localStorage.getItem(KEY_STORAGE_NOME) || '',
};

export const clearStorage = () => {
  localStorage.removeItem(KEY_STORAGE);
};

export const isHistoryIsValid = () => {
  const carrinhoStorage = localStorage.getItem(KEY_STORAGE);
  if (carrinhoStorage) {
    const carrinho = JSON.parse(carrinhoStorage) || initialState.cardapio;
    const historyIsValid = isDateMoreOneDay(carrinho.createAt);
    return historyIsValid;
  }
  return true;
};

const getInitialState = () => {
  try {
    const carrinhoStorage = localStorage.getItem(KEY_STORAGE);
    if (carrinhoStorage) {
      const carrinho = JSON.parse(carrinhoStorage) || initialState.cardapio;
      const historyIsValid = isDateMoreOneDay(carrinho.createAt);
      if (!carrinho.quantidade || !historyIsValid) {
        localStorage.removeItem(KEY_STORAGE);
        return initialState;
      }
      return {
        ...initialState,
        carrinho: JSON.parse(carrinhoStorage) || initialState.cardapio,
      };
    }
    return initialState;
  } catch (error) {
    return initialState;
  }
};

export const PedidosContext = createContext(getInitialState());

const REDUCERS = {
  FETCH_DATA: 'FETCH_DATA',
  FETCH_DATA_SUCCESS: 'FETCH_DATA_SUCCESS',
  FETCH_DATA_FAILURE: 'FETCH_DATA_FAILURE',
  ADD_ITEM: 'ADD_ITEM',
  UPDATE_ITEM: 'UPDATE_ITEM',
  REMOVE_ITEM: 'REMOVE_ITEM',
  UPDATE_CARRINHO: 'UPDATE_CARRINHO',
  SELECT_ITEM: 'SELECT_ITEM',
  LIMPAR: 'LIMPAR',
  UPDATED: 'UPDATED',
  FINALIZADO: 'FINALIZADO',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUCERS.ADD_ITEM:
      const items = [...state.carrinho.items, action.item];
      const total = items.reduce((il, it) => il + it.total, 0);
      return {
        ...state,
        carrinho: {
          ...state.carrinho,
          total,
          quantidade: items.length,
          items,
        },
      };
    case REDUCERS.UPDATE_ITEM:
      const { itemId, item } = action;
      const itemsUpdate = state.carrinho.items.map(it =>
        it.id === +itemId ? item : it,
      );
      const totalUpdate = itemsUpdate.reduce((il, it) => il + it.total, 0);
      return {
        ...state,
        carrinho: {
          ...state.carrinho,
          items: itemsUpdate,
          total: totalUpdate,
        },
      };
    case REDUCERS.REMOVE_ITEM:
      const itemsFilter = state.carrinho.items.filter(
        it => it.id !== +action.itemId,
      );
      return {
        ...state,
        carrinho: {
          ...state.carrinho,
          total: itemsFilter.reduce((il, it) => il + it.total, 0),
          quantidade: itemsFilter.length,
          items: itemsFilter,
        },
      };
    case REDUCERS.SELECT_ITEM:
      return {
        ...state,
        selectedItem: action.item,
      };
    case REDUCERS.UPDATE_CARRINHO:
      return {
        ...state,
        carrinho: {
          ...state.carrinho,
          ...action,
        },
      };
    case REDUCERS.FETCH_DATA:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case REDUCERS.UPDATED:
      return {
        ...state,
        ...action,
      };
    case REDUCERS.FINALIZADO:
      return {
        ...state,
        carrinho: {
          ...state.carrinho,
          finalizado: true,
          finishAt: new Date(),
        },
        ...action,
      };
    case REDUCERS.FETCH_DATA_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false,
        ...action,
      };
    case REDUCERS.FETCH_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case REDUCERS.LIMPAR:
      localStorage.removeItem(KEY_STORAGE);
      return {
        ...state,
        pedidoId: '',
        numeroMesa: null,
        carrinho: {
          id: generateUid(),
          ...initialState.carrinho,
        },
      };
    default:
      return state;
  }
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const useCarrinho = () => {
  const query = useQuery();
  const numeroMesa = useMemo(() => {
    return query.get('mesa') || query.get('numeroMesa');
  }, []);
  const [state, dispatch] = useReducer(reducer, {
    ...getInitialState(),
    numeroMesa,
  });
  const refs = useRef({});
  const handleAddItem = item => {
    dispatch({ type: REDUCERS.ADD_ITEM, item });
  };

  const handleSelectItem = item => {
    dispatch({ type: REDUCERS.SELECT_ITEM, item });
  };

  const limparCarrinho = useCallback(() => {
    dispatch({ type: REDUCERS.LIMPAR });
  }, []);

  const verifyHistoryIsValid = useCallback(() => {
    const historyIsValid = isHistoryIsValid();
    if (!historyIsValid) limparCarrinho();
  }, [limparCarrinho]);

  const removerItem = useCallback(itemId => {
    dispatch({ type: REDUCERS.REMOVE_ITEM, itemId });
  }, []);
  const updateItem = useCallback(actions => {
    dispatch({ type: REDUCERS.UPDATE_ITEM, ...actions });
  }, []);

  const handleOnUpdate = (props = {}) => {
    dispatch({ type: REDUCERS.UPDATE_CARRINHO, ...props });
  };
  const handleUpdateState = (props = {}) => {
    dispatch({ type: REDUCERS.UPDATED, ...props });
  };

  const handleFinalizar = () => {
    const {
      nome,
      numeroMesa: mesa,
      endereco,
      pedidoId: pedido_Id,
      carrinho: { entrega, pagamento, finalizado },
    } = state;
    if (!entrega || !pagamento || !nome || (entrega === 'entrega' && !endereco))
      return;
    localStorage.removeItem(KEY_STORAGE);

    let pedidoId = pedido_Id || '';

    if (finalizado) {
      // atualizando pedido
      analytics.logEvent('pedido_carrinho_finalizado_again');
      firestore.udpate({
        carrinho: state.carrinho,
        nome,
        endereco,
        pedidoId,
        numeroMesa: mesa,
      });
    } else {
      pedidoId = firestore.add({
        carrinho: state.carrinho,
        nome,
        endereco,
        numeroMesa: mesa,
      });
      const objetcAnalytic = {
        carrinhoId: state.carrinho?.id,
        numeroMesa: mesa,
        pedidoId,
        tipo_entrega: entrega,
        pagamento,
        currency: 'BRL',
        payment_type: pagamento,
        value: state.carrinho.total + (state.carrinho?.subtotal || 0),
        total: state.carrinho.total + (state.carrinho?.subtotal || 0),
        quantidade: state.carrinho.quantidade,
        nome: state.nome,
        endereco: state.endereco,
        ...(state.carrinho.selectedBairro
          ? {
              bairro: state?.carrinho?.bairro?.nome,
              taxaEntrega: state?.carrinho?.bairro?.taxa,
            }
          : {}),
        plataform: navigator?.platform,
      };
      analytics.logEvent('pedido_carrinho_finalizado', objetcAnalytic);
    }

    const params = whatsappLink({
      carrinho: state.carrinho,
      nome: state.nome,
      endereco: state.endereco,
      pedidoId,
      numeroMesa: mesa,
    });

    window.open(
      `https://api.whatsapp.com/send?phone=${process.env.REACT_APP_PROJECT_WHATSAPP}&text=${params}`,
      '_blank',
    );
    dispatch({ type: REDUCERS.FINALIZADO, pedidoId });
  };

  // CARREGAMENTO INICIAL
  const carregarPlanilha = useCallback(async () => {
    try {
      dispatch({ type: REDUCERS.FETCH_DATA });
      const { dados, ...restProps } = await getPlanilhaDelivery();

      refs.current = Object.keys(dados).reduce(
        (il, it) => ({ ...il, [it]: createRef() }),
        {},
      );

      dispatch({
        type: REDUCERS.FETCH_DATA_SUCCESS,
        cardapio: dados,
        isAberto: verificarIsAberto(restProps),
        ...restProps,
      });
    } catch (error) {
      dispatch({ type: REDUCERS.FETCH_DATA_FAILURE });
    }
  }, []);

  useEffect(() => {
    dispatch({ type: REDUCERS.FETCH_DATA });
    setTimeout(() => carregarPlanilha(), 300);
    localStorage.removeItem(KEY_STORAGE_SCROLL_LEFT);
  }, []);

  useEffect(() => {
    localStorage.setItem(KEY_STORAGE, JSON.stringify(state.carrinho));
  }, [state.carrinho]);
  useEffect(() => {
    localStorage.setItem(KEY_STORAGE_NOME, state.nome);
  }, [state.nome]);
  useEffect(() => {
    localStorage.setItem(KEY_STORAGE_ENDERECO, state.endereco);
  }, [state.endereco]);

  const cardapioItems = useMemo(() => {
    if (state.cardapio) {
      return [].concat.apply([], Object.values(state.cardapio));
    }
    return [];
  }, [state.cardapio]);

  return {
    handleAddItem,
    handleSelectItem,
    handleOnUpdate,
    limparCarrinho,
    handleUpdateState,
    removerItem,
    handleFinalizar,
    updateItem,
    verifyHistoryIsValid,
    refs,
    cardapioItems,
    carregarPlanilha,
    ...state,
  };
};

export const CarrinhoProvider = props => {
  const state = useCarrinho();
  return (
    <PedidosContext.Provider value={state}>
      {props.children}
    </PedidosContext.Provider>
  );
};
