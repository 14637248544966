/* eslint-disable no-restricted-globals */
// gera um ID radom para uso em diversos
import moment from 'moment';

export const generateUid = (date = new Date()) => {
  const s3 = () =>
    Math.floor((1 + Math.random()) * date.getTime())
      .toString(16)
      .substring(1);
  return `${s3(0x1000000000000000)}-${s3(0x1000000)}-${s3(0x1000000)}`;
};

export const verificarIsAberto = ({ horarios }) => {
  if (process.env.NODE_ENV === 'development') return true;
  if (!horarios) return false;
  try {
    const today = moment();
    const [horarioDia] = horarios.filter(
      it => it.dia === today.day() || +it.dia === today.day(),
    );

    const horaAbre = moment(horarioDia.abre, 'HH:mm').format();
    const horaFecha = moment(horarioDia.fecha, 'HH:mm').format();

    return horarioDia.aberto && moment().isBetween(horaAbre, horaFecha);
  } catch (error) {
    return true;
  }
};

export const verificaDiaOferta = (diasoferta: string) => {
  const dia = new Date().getDay();
  const isHojeTemOferta = diasoferta?.split(',').find(it => +it === dia);
  return Boolean(isHojeTemOferta);
};
