import moment from 'moment';
import { generateUid, verificaDiaOferta } from './utils';

const fields_default = [
  'codigo',
  'nome',
  'quantidade',
  'unidade',
  'descricao',
  'valor',
  'section',
  'col',
  'tipo',
];

const fields_delivery = [
  'codigo',
  'nome',
  'quantidade',
  'unidade',
  'descricao',
  'picture',
  'price',
  'valoroferta',
  'diasoferta',
  'serve',
  'valor',
  'section',
  'opcoesgrupo',
  'opcoesvalores',
  'opcoesmin',
  'opcoesmax',
  'opcoesdescricao',
  'acrescimogrupo',
  'acrescimovalores',
  'acrescimomax',
  'acrescimomim',
  'acrescimodescricao',
  'acrescimoplus',
  'acrescimoplusmax',
  'acrescimoobrigatorio',
  //
  'apartir',
  'disponivel',
  'temacrescimos',
  'custoembalagem',
];

export const formatarPlanilha = (json, type) => {
  const {
    feed: { entry },
  } = json;
  let fields = fields_default;
  if (type === 'delivery') fields = fields_delivery;
  const dados = entry.reduce((il, item) => {
    const values = {};
    fields.forEach(it => {
      try {
        values[it] = item[`gsx$${it}`].$t || null;
      } catch (error) {
        values[it] = null;
      }
    });
    return {
      ...il,
      [values.section]: [...(il[values.section] || []), values],
    };
  }, {});
  const tabletipo = Object.values(dados)
    .flat()
    .filter(it => it.tipo);
  if (tabletipo.length) {
    const tableakeys = tabletipo.reduce((il, item) => {
      return {
        ...il,
        [item.section]: {
          ...(il[item.section] || {}),
          [item.codigo]: il[item.section]
            ? [...(il[item.section][item.codigo] || []), item]
            : [item],
        },
      };
    }, {});
    Object.keys(tableakeys).forEach(key => {
      const values = Object.values(tableakeys[key]).map(it => {
        const [value] = it;
        const tabela = it.reduce((il, item) => {
          const { quantidade, unidade, valor } = item;
          return {
            ...il,
            [quantidade]: {
              ...(il[quantidade] || {}),
              unidade,
              valor,
              quantidade,
            },
          };
        }, {});
        return {
          ...value,
          tabela,
        };
      });
      dados[key] = values;
    });
  }

  return Object.values(dados).reduce((il, item) => {
    const [linha] = item;

    return {
      ...il,
      [linha.section]: item.sort((a, b) => +a.codigo - +b.codigo),
    };
  }, {});
};

const formatarAcrescimos = ({
  acrescimogrupo,
  acrescimovalores,
  opcoes: opcoesCustom,
  acrescimomax,
  acrescimomim,
  acrescimodescricao,
  acrescimoplus,
  acrescimoplusmax,
  acrescimoobrigatorio,
  temacrescimos,
}) => {
  if (!temacrescimos) return null;
  try {
    let opcoes = opcoesCustom;

    if (acrescimogrupo && acrescimovalores) {
      const prices = acrescimovalores.split(',').map(it => parseFloat(it));
      opcoes = acrescimogrupo.split(',').map((opcao, index) => ({
        id: generateUid(),
        nome: opcao,
        price: prices[index] || 0,
        quantidade: 0,
        total: 0,
      }));
    }

    return {
      min: +acrescimomim,
      max: +acrescimomax,
      descricao: acrescimodescricao || 'Acréscimos',
      isPlusType: Boolean(acrescimoplus),
      quantidadeMax: +acrescimoplusmax,
      isRequired: Boolean(acrescimoobrigatorio),
      opcoes,
    };
  } catch (error) {
    return null;
  }
};

export const formatStringPriceToNumber = (array, coluna?: string) => {
  if (!coluna) return array;
  return array?.map(item => ({
    ...item,
    [coluna]: parseFloat(item[coluna].replace(',', '.')),
  }));
};

const formatarOpcoes = ({
  opcoesgrupo,
  opcoesmax,
  opcoesmin,
  opcoesdescricao,
  opcoesvalores,
}) => {
  if (!opcoesgrupo) return null;
  try {
    const prices = (opcoesvalores || '').split(',').map(it => parseFloat(it));
    const opcoes = opcoesgrupo.split(',').map((opcao, index) => ({
      id: generateUid(),
      nome: opcao,
      price: prices[index] || 0,
    }));

    return {
      min: +opcoesmin || 1,
      max: +opcoesmax,
      descricao: opcoesdescricao || 'Escolha suas opções',
      opcoes,
    };
  } catch (error) {
    return null;
  }
};

const itemsRequeried = ['section', 'codigo', 'nome', 'valor', 'price'];

const checkItemsRequeried = values =>
  itemsRequeried.some(item => !values[item]);

function formatEntries(colunas, values) {
  return colunas.reduce(
    (obj, key, index) => ({ ...obj, [key]: values[index] || null }),
    [],
  );
}

export const generatePlanilhaFromApiV4 = (
  planilhaValues: string[][],
  disableCheck = false,
) => {
  const [colunas] = planilhaValues;

  const entry = planilhaValues
    .slice(1, planilhaValues.length)
    .map(values => formatEntries(colunas, values));

  if (disableCheck) return entry;

  return entry.reduce((il, values) => {
    if (
      values?.disponivel ||
      checkItemsRequeried(values) ||
      // se tem promoção hoje
      (values.diasoferta && !verificaDiaOferta(values.diasoferta))
    )
      return il;

    return {
      ...il,
      [values.section]: [...(il[values.section] || []), values],
    };
  }, {});
};

const generatePlanilhaFromApiV3 = (entry, fields) =>
  entry.reduce((il, item) => {
    const values = {};
    fields.forEach(it => {
      try {
        values[it] = item[`gsx$${it}`].$t || null;
      } catch (error) {
        values[it] = null;
      }
    });

    if (
      values.disponivel ||
      checkItemsRequeried(values) ||
      // se tem promoção hoje
      (values.diasoferta && !verificaDiaOferta(values.diasoferta))
    )
      return il;

    return {
      ...il,
      [values.section]: [...(il[values.section] || []), values],
    };
  }, {});

export const formatarPlanilhaDelivery = (
  json = {},
  acrescimo_grupo,
  isV4 = false,
  planilhaValues,
) => {
  let dados = null;
  if (isV4) dados = generatePlanilhaFromApiV4(planilhaValues);
  else {
    const {
      feed: { entry },
    } = json;
    const fields = fields_delivery;
    dados = generatePlanilhaFromApiV3(entry, fields);
  }

  Object.keys(dados).forEach(key => {
    const opcoestipo = dados[key].filter(it => it.opcoesgrupo);
    if (opcoestipo.length) {
      const values = dados[key].map(it => {
        const value = it;
        const {
          opcoesgrupo,
          opcoesmin,
          opcoesmax,
          opcoesdescricao,
          opcoesvalores,
          ...restValues
        } = value;
        return {
          ...restValues,
          opcoes_grupo: formatarOpcoes({
            opcoesgrupo,
            opcoesmin,
            opcoesmax,
            opcoesdescricao,
            opcoesvalores,
          }),
        };
      });
      dados[key] = values;
    }

    const acrescimotipo = dados[key].filter(it => it.temacrescimos);
    if (acrescimotipo.length) {
      const values = dados[key].map(it => {
        const value = it;
        const {
          acrescimogrupo,
          acrescimovalores,
          acrescimomax,
          acrescimomim,
          acrescimodescricao,
          acrescimoplus,
          acrescimoplusmax,
          acrescimoobrigatorio,
          temacrescimos,
          ...restValues
        } = value;
        return {
          ...restValues,
          acrescimo_grupo: formatarAcrescimos({
            opcoes: acrescimo_grupo,
            acrescimogrupo,
            acrescimomax,
            acrescimomim,
            acrescimovalores,
            acrescimodescricao,
            acrescimoplus,
            acrescimoplusmax,
            acrescimoobrigatorio,
            temacrescimos,
          }),
        };
      });
      dados[key] = values;
    }
  });

  return Object.values(dados).reduce((il, item) => {
    const [linha] = item;

    return {
      ...il,
      [linha.section]: item.sort((a, b) => +a.codigo - +b.codigo),
    };
  }, {});
};

const fields_delivery_bairros = ['nome', 'taxa'];

export const formatarPlanilhaBairros = json => {
  const {
    feed: { entry },
  } = json;
  const fields = fields_delivery_bairros;

  const dados = entry.reduce((il, item) => {
    const values = {};
    fields.forEach(it => {
      try {
        values[it] = item[`gsx$${it}`].$t || null;
      } catch (error) {
        values[it] = null;
      }
    });
    return [...il, values];
  }, []);

  return dados.map(it => ({
    ...it,
    taxa: parseFloat(it.taxa.replace(',', '.')),
  }));
};

const fields_delivery_horarios = ['dia', 'nome', 'abre', 'fecha', 'aberto'];

export const formatarPlanilhaHorarios = json => {
  const {
    feed: { entry },
  } = json;
  const fields = fields_delivery_horarios;

  const dados = entry.reduce((il, item) => {
    const values = {};
    fields.forEach(it => {
      try {
        values[it] = item[`gsx$${it}`].$t || null;
      } catch (error) {
        values[it] = null;
      }
    });
    return [...il, values];
  }, []);

  return dados;
};

const fields_delivery_acrescimos = [
  'id',
  'codigo',
  'nome',
  'price',
  'max',
  'min',
];

export const formatarPlanilhaAcrescimos = json => {
  const {
    feed: { entry },
  } = json;
  const fields = fields_delivery_acrescimos;

  const dados = entry.reduce((il, item) => {
    const values = {};
    fields.forEach(it => {
      try {
        values[it] = item[`gsx$${it}`].$t || null;
      } catch (error) {
        values[it] = null;
      }
    });
    return [...il, values];
  }, []);

  return dados.map(it => ({
    ...it,
    price: parseFloat(it.price.replace(',', '.')),
    quantidade: 0,
    total: 0,
  }));
};
export function isMobileOrTablet() {
  return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
}

export const isDateMoreOneDay = date => {
  if (!date) return !date;
  return !(moment().diff(date, 'days') > 1);
};

export const removerAcentos = s =>
  s
    .normalize('NFD')
    .replace(/[\u0300-\u036f|\u00b4|\u0060|\u005e|\u007e]/g, '');
