import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { COLORS } from 'styles';

const Loading = ({
  bsClass,
  className,
  style,
  type,
  message,
  showMsg,
  messageClass,
  disabledHeight,
}) => (
  <div
    className={classnames(bsClass, className)}
    style={disabledHeight ? {} : style}
  >
    <ReactLoading
      type={type}
      color={COLORS.secondary}
      height={100}
      width={100}
    />
    {showMsg && (
      <p className={classnames('font-weight-bold text-muted', messageClass)}>
        {message}
      </p>
    )}
  </div>
);

Loading.defaultProps = {
  bsClass: 'd-flex flex-column align-items-center justify-content-center',
  style: { height: '50vh' },
  disabledHeight: false,
  className: '',
  type: 'spin',
  showMsg: true,
  messageClass: '',
  message: 'Aguarde...',
};

Loading.propTypes = {
  disabledHeight: PropTypes.bool,
  messageClass: PropTypes.string,
  type: PropTypes.string,
  bsClass: PropTypes.string,
  className: PropTypes.string,
  showMsg: PropTypes.bool,
  message: PropTypes.string,
  style: PropTypes.instanceOf(Object),
};

export default Loading;
