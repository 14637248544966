import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { PageFound, Loading } from 'components';
import { CarrinhoProvider, clearStorage } from 'contexts/carrinho-context';
import 'index.css';
import {
  PEDIDO_ROUTE_MAIN,
  PEDIDO_DETALHES_ROUTER,
  PEDIDO_CARRINHO_ROUTER,
} from 'pages/Pedidos/routes';
import { analytics } from 'config/firebase';
import { SOBRE_ROUTER } from 'pages/Sobre/routes';
import { DashboardProvider } from 'pages/Dashboard/shared/use-dashboard';
import ErrorApp from './components/ErrorApp/ErrorApp';

import { BUSCA_ROUTER } from './pages/Busca/routes';
import { DASHBOARD_ROUTER } from './pages/Dashboard/routes';
import DashboardScreen from './pages/Dashboard';

const Pedidos = lazy(() =>
  import(/* webpackChunkName: "Pedidos" */ 'pages/Pedidos/Pedidos'),
);
const Busca = lazy(() =>
  import(/* webpackChunkName: "Busca" */ './pages/Busca/Busca'),
);
const SobrePage = lazy(() =>
  import(/* webpackChunkName: "Sobre" */ 'pages/Sobre/Sobre'),
);
const PedidosDetalhes = lazy(() =>
  import(/* webpackChunkName: "Detalhes" */ 'pages/Pedidos/pages/Detalhes'),
);
const Carrinho = lazy(() =>
  import(/* webpackChunkName: "Carrinho" */ 'pages/Pedidos/pages/Carrinho'),
);
const Footer = lazy(() => import('components/Footer/Footer'));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV !== 'development') clearStorage();
    // You can also log the error to an error reporting service
    analytics.logEvent('page_error', {
      error,
      errorInfo,
    });
    // TODO: ERROS DE CACHES
    if (caches) {
      caches.keys().then(async names => {
        await Promise.all(names.map(name => caches.delete(name)));
      });
    }

    // // delete browser cache and hard reload
    window.location.reload();
  }

  render() {
    const { hasError } = this.state;
    if (hasError && process.env.NODE_ENV !== 'development') {
      // You can render any custom fallback UI
      return <ErrorApp />;
    }

    return (
      <Router>
        <main>
          <Switch>
            <Route
              exact
              path={[
                PEDIDO_ROUTE_MAIN,
                ...[
                  `${PEDIDO_DETALHES_ROUTER}/:codigo`,
                  [`${PEDIDO_DETALHES_ROUTER}/:codigo/edit/:itemId`],
                ],
                PEDIDO_CARRINHO_ROUTER,
                SOBRE_ROUTER,
                BUSCA_ROUTER,
              ]}
            >
              <Suspense fallback={<Loading style={{ height: '100vh' }} />}>
                <CarrinhoProvider>
                  <Router>
                    <Switch>
                      <Route
                        exact
                        path={PEDIDO_ROUTE_MAIN}
                        component={Pedidos}
                      />
                      <Route
                        exact
                        path={[
                          `${PEDIDO_DETALHES_ROUTER}/:codigo`,
                          [`${PEDIDO_DETALHES_ROUTER}/:codigo/edit/:itemId`],
                        ]}
                        component={PedidosDetalhes}
                      />
                      <Route
                        exact
                        path={PEDIDO_CARRINHO_ROUTER}
                        component={Carrinho}
                      />
                      <Route exact path={SOBRE_ROUTER} component={SobrePage} />
                      <Route exact path={BUSCA_ROUTER} component={Busca} />
                      <Route component={PageFound} />
                      <Footer />
                    </Switch>
                  </Router>
                </CarrinhoProvider>
              </Suspense>
            </Route>
            <Route exact path={[DASHBOARD_ROUTER]}>
              <DashboardProvider>
                <Router>
                  <Switch>
                    <Route
                      exact
                      path={DASHBOARD_ROUTER}
                      component={DashboardScreen}
                    />
                  </Switch>
                </Router>
              </DashboardProvider>
            </Route>
            <Route component={PageFound} />
          </Switch>
        </main>
      </Router>
    );
  }
}

export default App;
