/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useMemo, useReducer, useContext } from 'react';

export const DashboardContext = React.createContext({});

export const DashboardProvider: React.FC = ({ children }) => (
  <DashboardContext.Provider value={{}}>{children}</DashboardContext.Provider>
);

export const useDashboard = (): unknown => {
  const context = useContext(DashboardContext);
  return context;
};
