import styled from 'styled-components';

export const BorderTop = styled.div`
  width: 120px;
  display: inline-block;
  height: 8px;
  background-image: linear-gradient(to left, #d63031 50%, #fb9a02 50%);
`;

export const TitleCardapio = styled.h1`
  color: #7d5b3b;
  font-size: calc(3rem + 0.5vw);
  text-align: center;
  font-family: 'Oswald';

  letter-spacing: 2.4px;
`;
