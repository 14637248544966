/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import logo from 'assets/logo.png';
import { Title } from 'styles';
import ContainerPedidos from '../Pedidos/components/ContainerPedidos';
import HeaderChama from '../Pedidos/components/HeaderChama';

import { LogoImage, LogoContent } from '../Pedidos/styles';
import { useDashboard } from './shared/use-dashboard';

const DashboardScreen: React.FC = () => {
  // useDashboard();
  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_PROJECT_EMPRESA} - Faça seu pedido</title>
      </Helmet>
      <HeaderChama />
      <LogoContent className="pb-0">
        <Link to="/">
          <LogoImage height={60} alt="logo" src={logo} />
        </Link>
      </LogoContent>
      <ContainerPedidos>
        <Title>Dashboard</Title>
      </ContainerPedidos>
    </>
  );
};

export default DashboardScreen;
