/* eslint-disable import/no-cycle */
import React, { useCallback } from 'react';
import { Row, Col, InputGroup } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import { COLORS } from 'styles';
import { usePedidosContext } from 'hooks/usePedidos';
import CurrencyInput from 'react-intl-currency-input';
import CheckButton from './CheckButton';
import { ListItemTitle, ListItemDescription, CircleBadge } from '../styles';

const currencyConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        style: 'decimal',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export const TIPO_PAGAMENTOS = [
  {
    id: 'dinheiro',
    nome: 'Dinheiro',
    adicional: null,
  },
  {
    id: 'credito',
    nome: 'Crédito',
    adicional: null,
  },
  {
    id: 'debito',
    nome: 'Débito',
    adicional: null,
  },
  {
    id: 'pix',
    nome: 'PIX',
    adicional: 'Chave: 88999789494 (Enviar comprovante)',
    chave: 88999789494,
  },
  {
    id: 'cartao-refeicao',
    nome: 'Cartão Refeição',
    adicional: 'Alelo Refeição, Ticket Restaurante, Sodexo',
  },
];

const TipoPagamento = () => {
  const { handleOnUpdate, carrinho } = usePedidosContext();
  const onSelected = useCallback(event => {
    handleOnUpdate({ pagamento: event.currentTarget.name });
  }, []);
  const handleOnChange = useCallback((event, value) => {
    handleOnUpdate({ troco: value });
  }, []);
  const { pagamento: selected, troco } = carrinho;
  return (
    <>
      <Row className="justify-content-between mt-4">
        <Col xs="auto">
          <ListItemTitle fontSize="1.2rem">Como você vai pagar</ListItemTitle>
        </Col>
        <Col xs="auto">
          {selected ? (
            <FaCheckCircle size={24} color={COLORS.success} />
          ) : (
            <CircleBadge>Obrigatório</CircleBadge>
          )}
        </Col>
      </Row>
      {TIPO_PAGAMENTOS.map(tipo => (
        <React.Fragment key={tipo.id}>
          <Row
            as="a"
            className="justify-content-between mt-2 text-decoration-none"
            key={tipo.id}
            style={{ cursor: 'pointer' }}
            name={tipo.id}
            onClick={onSelected}
          >
            <Col xs="auto">
              <ListItemDescription fontSize="1.2rem" className="m-0">
                {tipo.nome}
              </ListItemDescription>
              <ListItemDescription fontSize="0.85rem" className="m-0">
                {tipo.adicional}
              </ListItemDescription>
            </Col>
            <Col xs="auto">
              <CheckButton
                id={tipo.id}
                value={tipo.id}
                checked={selected === tipo.id}
              />
            </Col>
          </Row>
          {selected === 'dinheiro' && tipo.id === 'dinheiro' && (
            <Row>
              <Col xs="auto" className="ml-1">
                <ListItemDescription
                  fontSize="0.95rem"
                  className="m-0 font-weight-bold"
                >
                  Troco para quanto?
                </ListItemDescription>
                <InputGroup className="mb-2 mr-sm-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <ListItemDescription
                        fontSize="0.9rem"
                        className="m-0 font-weight-bold"
                      >
                        R$
                      </ListItemDescription>
                    </InputGroup.Text>
                  </InputGroup.Prepend>

                  <CurrencyInput
                    className="form-control"
                    id="troco"
                    onChange={handleOnChange}
                    type="tel"
                    pattern="[\d\.]*"
                    min="0"
                    step="0.01"
                    max={99}
                    placeholder="0,00"
                    value={troco}
                    currency="BRL"
                    config={currencyConfig}
                  />
                </InputGroup>
              </Col>
            </Row>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default TipoPagamento;
