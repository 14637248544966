import styled from 'styled-components';
import { COLORS } from 'styles';

const HEIGHT = 400;

export const Background = styled.div`
  background: ${COLORS.secondary};
  ::before {
    content: '';
    width: 100%;
    position: absolute;
    right: 0;
    width: 100%;
    height: ${HEIGHT - 20}px;
background-size: cover;
    opacity: 0.4;
    z-index: 0;
background-position: center;
      height: ${HEIGHT - 50 - 20}px;
    }
  }

  @media only screen and (max-width: 575.98px) {
    ::before {
      background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0, #582200 90%);
      background-position: center;
      height: ${HEIGHT - 150 - 20}px;
    }
    ::after {
      background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0, #582200 100%);
    }
  }
`;

export const Header = styled.header`
  height: ${HEIGHT}px;
  padding-top: 20px;
  background: ${COLORS.secondary};
  @media only screen and (max-width: 767.98px) {
    height: ${HEIGHT - 50}px;
  }
  @media only screen and (max-width: 575.98px) {
    height: ${HEIGHT - 150}px;
  }
`;

export const LogoContent = styled.div`
  display: flex;
  padding: 20px;
  margin-top: 20px;
  border-radius: 154px;
  background: rgb(252, 222, 194);
  width: 180px;
  height: 180px;
  justify-content: center;
  align-items: center;
  img {
    height: 60%;
  }
`;

export const Logo = styled.img`
  height: 8.5vh;
  text-align: center;
`;
export const Sublogo = styled.p`
  margin: 0;
  font-family: 'Anton', sans-serif;
  color: ${COLORS.primary};
  font-size: 14px;
  letter-spacing: 0.5px;
`;
export const Link = styled.a`
  display: block;
  margin-top: 10px;
  text-decoration: none;
  :hover {
    text-decoration: none;
    opacity: 0.5;
  }
`;

export const Title = styled.h1`
  color: ${COLORS.accent};
  font-size: calc(1rem + 2vw);
  font-family: 'Anton', sans-serif;
  text-shadow: 2px 2px 2px #7a3700;
  padding-right: 4px;
  letter-spacing: 1px;
`;

export const Section = styled.section`
  color: white;
`;

export const Button = styled.a`
  display: block;
  margin-top: 20px;
  color: white;
  background: ${COLORS.secondary};
  padding: 20px;
  font-weight: bold;
  padding-right: 40px;
  padding-left: 40px;
`;

export const Texto = styled.p`
  margin: 10px;
  font-family: 'Oswald', sans-serif;
  color: ${COLORS.texto};
  font-size: 1.3rem;
`;
